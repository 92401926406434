<template>
  <form id="contrato">

    <h4>Dados do Contrato</h4>

    <div class="form-group">
      <div class="v-input input-field">
        <fieldset class="fieldset" :class="{'error-field': isValid(errosDocumento)}">
          <legend>CPF/CNPJ</legend>
          <b-form-input
            id="documento"
            v-model="documento"
            placeholder="Informe seu CPF/CNPJ"
            v-facade="['###.###.###-##', '##.###.###/####-##']"
            v-on:keyup="validInput('documento')"
          />
        </fieldset>
      </div>
      <b-form-invalid-feedback :force-show="isValid(errosDocumento)">
        <div v-for="(erro, index) in errosDocumento" :key="`error-documento-${index}`">
          {{ erro }}
        </div>
      </b-form-invalid-feedback>
    </div>

    <div class="form-group">
      <div class="v-input input-field">
        <fieldset class="fieldset" :class="{'error-field': isValid(errosCep)}">
          <legend>CEP</legend>
          <b-form-input
            id="cep"
            v-model="cep"
            placeholder="__.___-___"
            autocomplete="shipping postal-code"
            v-facade="'##.###-###'"
            v-on:keyup="validInput('cep')"
          />
        </fieldset>
      </div>
      <b-form-invalid-feedback :force-show="isValid(errosCep)">
        <div v-for="(erro, index) in errosCep" :key="`error-cep-${index}`">
          {{ erro }}
        </div>
      </b-form-invalid-feedback>
    </div>

    <placa/>

    <b-btn
      class="mt-4"
      variant="main"
      size="lg"
      block
      :loading="loading"
      :disabled="blacklist"
      @click="finish"
    >
      FINALIZAR CONTRATAÇÃO
    </b-btn>
  </form>
</template>

<script>
import Placa from "@/components/Placa";

export default {
  name: "Cartao",
  components: {
    Placa,
  },
  computed: {
    errosCep() {
      return this.$store.state.erros.checkout.cep;
    },
    errosDocumento() {
      return this.$store.state.erros.checkout.documento;
    },
    checkout() {
      return this.$store.state.checkout;
    },
    placa() {
      return this.$store.state.checkout.placa;
    },
    fipe() {
      return this.$store.state.checkout.fipe;
    },
    blacklist() {
      return this.$store.state.cotacao.blacklist;
    },
    loading() {
      return this.$store.state.loading.discrepancia;
    },
    cep: {
      get() {
        return this.$store.state.checkout.cep;
      },
      set(value) {
        this.$store.dispatch('setCheckoutCep', value);
      }
    },
    documento: {
      get() {
        return this.$store.state.checkout.documento;
      },
      set(value) {
        this.$store.commit('SET_CHECKOUT_DOCUMENTO', value);
      }
    },
  },
  methods: {
    validate() {

      let errosCheckout = {};

      if(this.isNullOrEmpty(this.placa)) {
        errosCheckout.placa = ['O campo Placa é obrigatório.'];
      }
      if(this.isNullOrEmpty(this.documento)) {
        errosCheckout.documento = ['O campo CPF/CNPJ é obrigatório.'];
      }
      if(this.isNullOrEmpty(this.cep)) {
        errosCheckout.cep = ['O campo CEP é obrigatório.'];
      }
      if(this.isNullOrEmpty(this.fipe)) {
        errosCheckout.fipe = ['O campo Modelo é obrigatório.'];
      }

      if(Object.keys(errosCheckout).length > 0) {
        this.$store.commit('SET_ERROS_CHECKOUT', errosCheckout);
      }

      return Object.keys(errosCheckout).length === 0;
    },
    validInput(field) {
      if (Object.hasOwn(this.checkout, field)) {
        if (!this.isNullOrEmpty(this.checkout[field])) {
          this.$store.commit('SET_ERRO_CHECKOUT', {key: field, value: []});
        }
      }
    },
    isNullOrEmpty(value) {
      return value === null || value === '';
    },
    isValid(input) {
      return input.length > 0 ? true : null;
    },
    finish() {
      if (!this.validate()) {
        return;
      }
      const query = {
        id: this.$store.state.cotacao.id,
        indicacao: this.$store.state.checkout.id_indicacao,
        ref: this.$store.state.simulacao.indicador,
      };
      this.$router.push({name: 'checkout', query});
    },
  },
}
</script>

<style scoped>

h4 {
  margin: 1.5rem 0;
}

</style>
