<template>
  <main>
    <div class="box-area">
      <div class="box-single d-flex align-items-center bg-box">
        <form class="container box-container">
          <div class="row justify-content-center">
            <div class="col-md-6">

              <h4>Dados Pessoais</h4>

              <div class="form-group">
                <div class="v-input input-field">
                  <fieldset class="fieldset" :class="{'error-field': isValid(erros.nome)}">
                    <legend>Nome</legend>
                    <b-form-input
                      id="nome"
                      v-model="nome"
                      placeholder="Informe seu nome"
                      v-on:keyup="validInput('nome')"
                    />
                  </fieldset>
                </div>
                <b-form-invalid-feedback :force-show="isValid(erros.nome)">
                  <div v-for="(erro, index) in erros.nome" :key="`error-nome-${index}`">
                    {{ erro }}
                  </div>
                </b-form-invalid-feedback>
              </div>

              <div class="form-group">
                <div class="v-input input-field">
                  <fieldset class="fieldset" :class="{'error-field': isValid(erros.email)}">
                    <legend>Email</legend>
                    <b-form-input
                      id="email"
                      v-model="email"
                      placeholder="Informe seu email"
                      v-on:keyup="validInput('email')"
                    />
                  </fieldset>
                </div>
                <b-form-invalid-feedback :force-show="isValid(erros.email)">
                  <div v-for="(erro, index) in erros.email" :key="`error-email-${index}`">
                    {{ erro }}
                  </div>
                </b-form-invalid-feedback>
              </div>

              <div class="form-group">
                <div class="v-input input-field">
                  <fieldset class="fieldset" :class="{'error-field': isValid(erros.telefone) }">
                    <legend>Telefone</legend>
                    <b-form-input
                      id="telefone"
                      v-model="telefone"
                      placeholder="(__) _____-____"
                      v-facade="'(##) #####-####'"
                      v-on:keyup="validInput('telefone')"
                    />
                  </fieldset>
                </div>
                <b-form-invalid-feedback :force-show="isValid(erros.telefone)">
                  <div v-for="(erro, index) in erros.telefone" :key="`error-telefone-${index}`">
                    {{ erro }}
                  </div>
                </b-form-invalid-feedback>
              </div>

              <div class="form-group">
                <div class="v-input input-field">
                  <fieldset class="fieldset" :class="{'error-field': isValid(erros.documento)}">
                    <legend>CPF/CNPJ</legend>
                    <b-form-input
                      id="documento"
                      v-model="documento"
                      placeholder="Informe seu CPF/CNPJ"
                      v-facade="['###.###.###-##', '##.###.###/####-##']"
                      v-on:keyup="validInput('documento')"
                    />
                  </fieldset>
                </div>
                <b-form-invalid-feedback :force-show="isValid(erros.documento)">
                  <div v-for="(erro, index) in erros.documento" :key="`error-documento-${index}`">
                    {{ erro }}
                  </div>
                </b-form-invalid-feedback>
              </div>

              <h4>Endereço</h4>

              <div class="row">
                <div class="form-group col-6">
                  <div class="v-input input-field">
                    <fieldset class="fieldset" :class="{'error-field': isValid(erros.cep)}">
                      <legend>CEP</legend>
                      <b-form-input
                        id="cep"
                        v-model="cep"
                        placeholder="__.___-___"
                        autocomplete="shipping postal-code"
                        v-facade="'##.###-###'"
                        v-on:keyup="validInput('cep')"
                      />
                    </fieldset>
                  </div>
                  <b-form-invalid-feedback :force-show="isValid(erros.cep)">
                    <div v-for="(erro, index) in erros.cep" :key="`error-cep-${index}`">
                      {{ erro }}
                    </div>
                  </b-form-invalid-feedback>
                </div>
              </div>

              <b-overlay :show="loadingCep">

                <div class="row">
                  <div class="form-group col-9">
                    <div class="v-input input-field">
                      <fieldset class="fieldset" :class="{'error-field': isValid(erros.endereco)}">
                        <legend>Endereço</legend>
                        <b-form-input
                          id="endereco"
                          v-model="endereco"
                          placeholder="Rua, Avenida, etc"
                          v-on:keyup="validInput('endereco')"
                        />
                      </fieldset>
                    </div>
                    <b-form-invalid-feedback :force-show="isValid(erros.endereco)">
                      <div v-for="(erro, index) in erros.endereco" :key="`error-endereco-${index}`">
                        {{ erro }}
                      </div>
                    </b-form-invalid-feedback>
                  </div>

                  <div class="form-group col-3">
                    <div class="v-input input-field">
                      <fieldset class="fieldset" :class="{'error-field': isValid(erros.numero)}">
                        <legend>Nº</legend>
                        <b-form-input
                          id="numero"
                          v-model="numero"
                          placeholder="Nº"
                          v-on:keyup="validInput('numero')"
                        />
                      </fieldset>
                    </div>
                    <b-form-invalid-feedback :force-show="isValid(erros.numero)">
                      <div v-for="(erro, index) in erros.numero" :key="`error-numero-${index}`">
                        {{ erro }}
                      </div>
                    </b-form-invalid-feedback>
                  </div>

                  <div class="form-group col-12">
                    <div class="v-input input-field">
                      <fieldset class="fieldset" :class="{'error-field': isValid(erros.complemento)}">
                        <legend>Complemento <small>(Opcional)</small></legend>
                        <b-form-input
                          id="complemento"
                          v-model="complemento"
                          placeholder="Informe o complemento"
                          v-on:keyup="validInput('complemento')"
                        />
                      </fieldset>
                    </div>
                    <b-form-invalid-feedback :force-show="isValid(erros.complemento)">
                      <div v-for="(erro, index) in erros.complemento" :key="`error-complemento-${index}`">
                        {{ erro }}
                      </div>
                    </b-form-invalid-feedback>
                  </div>

                  <div class="form-group col-12">
                    <div class="v-input input-field">
                      <fieldset class="fieldset" :class="{'error-field': isValid(erros.bairro)}">
                        <legend>Bairro</legend>
                        <b-form-input
                          id="bairro"
                          v-model="bairro"
                          placeholder="Informe o bairro"
                          v-on:keyup="validInput('bairro')"
                        />
                      </fieldset>
                    </div>
                    <b-form-invalid-feedback :force-show="isValid(erros.bairro)">
                      <div v-for="(erro, index) in erros.bairro" :key="`error-bairro-${index}`">
                        {{ erro }}
                      </div>
                    </b-form-invalid-feedback>
                  </div>

                  <div class="form-group col-sm-6">
                    <div class="v-input input-field">
                      <fieldset class="fieldset" :class="{'error-field': isValid(erros.cidade)}">
                        <legend>Cidade</legend>
                        <b-form-input
                          id="cidade"
                          v-model="cidade"
                          placeholder="Informe a cidade"
                          v-on:keyup="validInput('cidade')"
                        />
                      </fieldset>
                    </div>
                    <b-form-invalid-feedback :force-show="isValid(erros.cidade)">
                      <div v-for="(erro, index) in erros.cidade" :key="`error-cidade-${index}`">
                        {{ erro }}
                      </div>
                    </b-form-invalid-feedback>
                  </div>

                  <div class="col-sm-6">
                    <div class="v-input" :class="{'v-input-disabled': estados.length === 0}">
                      <fieldset class="fieldset" :class="{'error-field': isValid(erros.estado)}">
                        <legend><span>Estado</span></legend>
                        <div class="v-select">
                          <v-select
                            v-model="estado"
                            id="estado"
                            :loading="loadingEstados"
                            label="uf"
                            :clearable="false"
                            :disabled="estados.length === 0"
                            placeholder="Selecione um estado"
                            :reduce="opt => opt.uf"
                            :options="estados">
                            <div slot="no-options">Estado não encontrado</div>
                          </v-select>
                        </div>
                      </fieldset>
                    </div>
                    <b-form-invalid-feedback :force-show="isValid(erros.estado)">
                      <div v-for="(erro, index) in erros.estado" :key="`error-estado-${index}`">
                        {{ erro }}
                      </div>
                    </b-form-invalid-feedback>
                  </div>

                </div>

              </b-overlay>

            </div>

            <div class="col-md-6">
              <h4>Forma de pagamento</h4>
              <b-form-group class="radio-metodo">
                <template v-for="item in metodos">
                  <b-form-radio
                    v-model="metodo"
                    name="metodo"
                    :value="item.id"
                    :key="item.id"
                  >
                    <inline-svg class="icone"
                      :src="require('@/assets/metodos/' + item.id + '.svg')"
                    ></inline-svg>
                    <span>{{ item.label }}</span>
                  </b-form-radio>
                </template>
              </b-form-group>

              <cartao v-if="metodo === 'cartao'"/>

              <div class="w-100 border-top py-2"></div>

              <div class="resumo" v-if="adesao">
                <strong>Resumo da Proteção Veicular</strong>
                <div>
                  <div class="d-flex justify-content-between">
                    <div>Veículo</div>
                    <div class="text-right">
                      {{ Number(plano).toLocaleString('pt-BR', format) }}
                    </div>
                  </div>
                  <template v-if="dadosPlaca">
                    <div><small>- Marca/Modelo: {{ dadosPlaca.marca_modelo }}</small></div>
                    <div><small>- Ano: {{ dadosPlaca.ano }}</small></div>
                    <div><small>- Cor: {{ dadosPlaca.cor }}</small></div>
                    <div><small>- Combustível: {{ dadosPlaca.combustivel }}</small></div>
                    <div><small>- Valor Fipe: {{ Number(cotacao.valor_fipe).toLocaleString('pt-BR', format) }}</small></div>
                    <div><small>- Código Fipe: {{ cotacao.fipe }}</small></div>
                    <div><small>- Chassi: {{ dadosPlaca.chassi }}</small></div>
                  </template>
                  <template v-else>
                    <div><small>- Marca/Modelo: {{ marca }} {{ modelo }}</small></div>
                    <div><small>- Ano: {{ ano }}</small></div>
                  </template>

                  <template v-if="opcionalAssistencia">
                    <div class="d-flex justify-content-between">
                      <div>Assistência 24 horas</div>
                      <div class="text-right">
                        {{ Number(opcionalAssistencia.valor).toLocaleString('pt-BR', format) }}
                      </div>
                    </div>
                    <div><small>- {{ opcionalAssistencia.descricao }}</small></div>
                  </template>
                  <template v-if="opcionalPar">
                    <div class="d-flex justify-content-between">
                      <div>PAR - Danos a terceiros</div>
                      <div class="text-right">
                        {{ Number(opcionalPar.valor).toLocaleString('pt-BR', format) }}
                      </div>
                    </div>
                    <div><small>- {{ opcionalPar.descricao }}</small></div>
                  </template>
                  <template v-if="opcionalCarro">
                    <div class="d-flex justify-content-between">
                      <div>Carro Reserva</div>
                      <div class="text-right">
                        {{ Number(opcionalCarro.valor).toLocaleString('pt-BR', format) }}
                      </div>
                    </div>
                    <div><small>- {{ opcionalCarro.descricao }}</small></div>
                  </template>
                  <template v-if="opcionalVidro">
                    <div class="d-flex justify-content-between">
                      <div>Desc. 70% - Vidros, Farois, Lanternas e Retrovisores</div>
                      <div class="text-right">
                        {{ Number(opcionalVidro.valor).toLocaleString('pt-BR', format) }}
                      </div>
                    </div>
                    <div><small>- {{ opcionalVidro.descricao }}</small></div>
                  </template>
                  <template v-if="opcionalMorte">
                    <div class="d-flex justify-content-between">
                      <div>M.A.O</div>
                      <div class="text-right">
                        {{ Number(opcionalMorte.valor).toLocaleString('pt-BR', format) }}
                      </div>
                    </div>
                    <div><small>- {{ opcionalMorte.descricao }}</small></div>
                  </template>
                </div>

                <div class="total d-flex justify-content-between">
                  <div>Total Mensal</div>
                  <div class="text-right">
                    {{ Number(mensal).toLocaleString('pt-BR', format) }}
                  </div>
                </div>
              </div>

              <div class="resumo" v-if="adesao">
                <strong>Resumo da Compra</strong>
                <div>
                  <div class="d-flex justify-content-between">
                    <div>
                      Adesão de Proteção Veícular
                    </div>
                    <div class="text-right">
                      {{ Number(adesao).toLocaleString('pt-BR', format) }}
                    </div>
                  </div>
                  <div>
                    <small>
                      <template v-if="dadosPlaca">
                        - {{ dadosPlaca.marca_modelo }} ({{ dadosPlaca.ano }})
                      </template>
                      <template v-else>
                        - {{ marca }} {{ modelo }} ({{ ano }})
                      </template>
                    </small>
                  </div>
                </div>
              </div>
              <template v-if="formHasError">
                <div class="alert alert-danger">
                  <strong>Verifique se todos os campos estão preenchidos antes de prosseguir!</strong>
                </div>
              </template>

              <b-overlay
                :show="loading"
              >
                <b-btn
                  class="mt-4"
                  variant="main"
                  size="lg"
                  block
                  :disabled="loading"
                  @click="save"
                >
                  FINALIZAR E PAGAR COMPRA
                </b-btn>
              </b-overlay>
            </div>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
/* eslint-disable no-undef */
import Cartao from '@/components/Cartao.vue';
import Placa from '@/components/Placa.vue';
import RdStation from '@/services/rdstation.js';

export default {
  name: 'Fechamento',
  components: {
    Cartao,
    Placa,
  },
  data() {
    return {
      format: {
        minimumFractionDigits: 2,
        style: 'currency',
        currency: 'BRL'
      },
      loading: false,
      metodos: [
        {
          id: 'cartao',
          label: 'Cartão',
        },
        {
          id: 'boleto',
          label: 'Boleto',
        },
        // {
        //   id: 'pix',
        //   label: 'Pix',
        // },
      ]
    }
  },
  computed: {
    loadingCep() {
      return this.$store.state.loading.cep;
    },
    loadingEstados() {
      return this.$store.state.loading.estados;
    },
    cotacao() {
      return this.$store.state.cotacao;
    },
    checkout() {
      return this.$store.state.checkout;
    },
    cartao() {
      return this.$store.state.cartao;
    },
    erros() {
      return this.$store.state.erros.checkout;
    },
    errosCartao() {
      return this.$store.state.erros.cartao;
    },
    estados() {
      return this.$store.state.estados;
    },
    adesao() {
      return this.$store.state.cotacao.valor_adesao;
    },
    marca() {
      return this.$store.state.cotacao.marca;
    },
    modelo() {
      return this.$store.state.cotacao.modelo;
    },
    ano() {
      return this.$store.state.cotacao.ano;
    },
    placa: {
      get() {
        return this.$store.state.checkout.placa;
      },
      set(value) {
        this.$store.commit('SET_CHECKOUT_PLACA', value);
      }
    },
    nome: {
      get() {
        return this.$store.state.checkout.nome;
      },
      set(value) {
        this.$store.commit('SET_CHECKOUT_NOME', value);
      }
    },
    email: {
      get() {
        return this.$store.state.checkout.email;
      },
      set(value) {
        this.$store.commit('SET_CHECKOUT_EMAIL', value);
      }
    },
    telefone: {
      get() {
        return this.$store.state.checkout.telefone;
      },
      set(value) {
        this.$store.commit('SET_CHECKOUT_TELEFONE', value);
      }
    },
    documento: {
      get() {
        return this.$store.state.checkout.documento;
      },
      set(value) {
        this.$store.commit('SET_CHECKOUT_DOCUMENTO', value);
      }
    },
    cep: {
      get() {
        return this.$store.state.checkout.cep;
      },
      set(value) {
        this.$store.dispatch('setCheckoutCep', value);
      }
    },
    endereco: {
      get() {
        return this.$store.state.checkout.endereco;
      },
      set(value) {
        this.$store.commit('SET_CHECKOUT_ENDERECO', value);
      }
    },
    numero: {
      get() {
        return this.$store.state.checkout.numero;
      },
      set(value) {
        this.$store.commit('SET_CHECKOUT_NUMERO', value);
      }
    },
    complemento: {
      get() {
        return this.$store.state.checkout.complemento;
      },
      set(value) {
        this.$store.commit('SET_CHECKOUT_COMPLEMENTO', value);
      }
    },
    bairro: {
      get() {
        return this.$store.state.checkout.bairro;
      },
      set(value) {
        this.$store.commit('SET_CHECKOUT_BAIRRO', value);
      }
    },
    cidade: {
      get() {
        return this.$store.state.checkout.cidade;
      },
      set(value) {
        this.$store.commit('SET_CHECKOUT_CIDADE', value);
      }
    },
    estado: {
      get() {
        return this.$store.state.checkout.estado;
      },
      set(value) {
        this.$store.commit('SET_CHECKOUT_ESTADO', value);
      }
    },
    metodo: {
      get() {
        return this.$store.state.checkout.metodo;
      },
      set(value) {
        this.$store.commit('SET_CHECKOUT_METODO', value);
      }
    },
    token: {
      get() {
        return this.$store.state.checkout.token;
      },
      set(value) {
        this.$store.commit('SET_CHECKOUT_TOKEN', value);
      }
    },
    opcionalAssistencia() {
      return this.$store.getters.getOpcionalAssistencia;
    },
    opcionalPar() {
      return this.$store.getters.getOpcionalPar;
    },
    opcionalCarro() {
      return this.$store.getters.getOpcionalCarro;
    },
    opcionalVidro() {
      return this.$store.getters.getOpcionalVidro;
    },
    opcionalMorte() {
      return this.$store.getters.getOpcionalMorte;
    },
    plano() {
      return Number(this.$store.state.cotacao?.valor_plano ?? 0);
    },
    mensal() {
      return this.$store.getters.getValorMensal;
    },
    dadosPlaca() {
      return this.$store.state.placa.veiculo;
    },
    formHasError() {
      const checkout = Object.values(this.erros);
      const cartao = Object.values(this.errosCartao);
      const erros = [...checkout, ...cartao];

      for (let i = 0; i < erros.length; i++){
        const erro = erros[i];
        if (erro.length > 0) {
          return true;
        }
      }

      return false;
    }
  },
  methods: {
    validate() {

      let errosCheckout = {},
        errosCartao = {};

      if(this.isNullOrEmpty(this.placa)) {
        errosCheckout.placa = ['O campo Placa é obrigatório.'];
      }
      if(this.isNullOrEmpty(this.nome)) {
        errosCheckout.nome = ['O campo Nome é obrigatório.'];
      }
      if(this.isNullOrEmpty(this.email)) {
        errosCheckout.email = ['O campo Email é obrigatório.'];
      }
      if(this.isNullOrEmpty(this.telefone)) {
        errosCheckout.telefone = ['O campo Telefone é obrigatório.'];
      }
      if(this.isNullOrEmpty(this.documento)) {
        errosCheckout.documento = ['O campo CPF/CNPJ é obrigatório.'];
      }
      if(this.isNullOrEmpty(this.cep)) {
        errosCheckout.cep = ['O campo CEP é obrigatório.'];
      }
      if(this.isNullOrEmpty(this.endereco)) {
        errosCheckout.endereco = ['O campo Endereço é obrigatório.'];
      }
      if(this.isNullOrEmpty(this.numero)) {
        errosCheckout.numero = ['Obrigatório.'];
      }
      if(this.isNullOrEmpty(this.bairro)) {
        errosCheckout.bairro = ['O campo Bairro é obrigatório.'];
      }
      if(this.isNullOrEmpty(this.cidade)) {
        errosCheckout.cidade = ['O campo Cidade é obrigatório.'];
      }
      if(this.isNullOrEmpty(this.estado)) {
        errosCheckout.estado = ['O campo Estado é obrigatório.'];
      }

      if(Object.keys(errosCheckout).length > 0) {
        this.$store.commit('SET_ERROS_CHECKOUT', errosCheckout);
      }

      if(this.checkout.metodo === 'cartao') {
        if(this.isNullOrEmpty(this.cartao.nome)) {
          errosCartao.nome = ['O campo Nome é obrigatório.'];
        }

        if(this.isNullOrEmpty(this.cartao.numero)) {
          errosCartao.numero = ['O campo Número é obrigatório.'];
        } else if (Iugu.utils.validateCreditCardNumber(this.cartao.numero) === false) {
          errosCartao.numero = ['Número do cartão inválido.'];
        } else {
          const brand = Iugu.utils.getBrandByCreditCardNumber(this.cartao.numero);
          if(this.isNullOrEmpty(this.cartao.codigo)) {
            errosCartao.codigo = ['O campo Código é obrigatório.'];
          } else if (Iugu.utils.validateCVV(this.cartao.codigo, brand) === false) {
            errosCartao.codigo = ['Código do cartão inválido.'];
          }
        }

        if(this.isNullOrEmpty(this.cartao.mes) || this.isNullOrEmpty(this.cartao.ano)) {
          errosCartao.validade = ['Validade é obrigatório.'];
        } else if (Iugu.utils.validateExpiration(this.cartao.mes, this.cartao.ano) === false) {
          errosCartao.validade = ['Data inválida.'];
        }

        if(Object.keys(errosCartao).length > 0) {
          this.$store.commit('SET_ERROS_CARTAO', errosCartao);
        }
      }

      return Object.keys(errosCheckout).length === 0 && Object.keys(errosCartao).length === 0;

    },
    async save() {
      await this.$nextTick();
      this.loading = true;

      try {
        if (!this.validate()) {
          return;
        }

        if (this.metodo === 'cartao') {
          await this.createPaymentToken();
        }

        await this.$store.dispatch('createCheckout');

        this.$router.push({ name: this.metodo, query: { ref: this.$store.state.simulacao.indicador } });

      } catch (error) {
        console.log(error);
      } finally {
        this.$nextTick(() => {
          this.loading = false;
        });
      }
    },
    createPaymentToken() {
      return new Promise((resolve, reject) => {
        const card = document.querySelector('#cartao');
        Iugu.createPaymentToken(card, (response) => {
          if (response.errors) {
            reject(response);
          } else {
            this.token = response.id;
            resolve();
          }
        });
      });
    },
    validInput(name) {
      if (Object.hasOwn(this.checkout, name)) {
        if (!this.isNullOrEmpty(this.checkout[name])) {
          this.$store.commit('SET_ERRO_CHECKOUT', {key: name, value: []});
        }
      }
    },
    isNullOrEmpty(value) {
      return value === null || value === '';
    },
    isValid(input) {
      return input.length > 0 ? true : null;
    }
  },
  beforeMount() {
    this.$store.dispatch('loadEstados');
  },
  async mounted() {
    // eslint-disable-next-line no-undef
    window.fbq('track', 'PageView');
    window.fbq('track', 'InitiateCheckout');

    const btnVoltar = document.querySelector('.btn-voltar');
    btnVoltar.style.display = 'none';

    this.metodo = 'cartao';

    let searchParams = new URLSearchParams(window.location.search);
    const cotacaoId = searchParams.get('id');
    if (this.$store.state.cotacao.id === null && cotacaoId) {
      await this.$store.dispatch('setCotacao', cotacaoId);

      const indicacao = searchParams.get('indicacao');
      if (this.$store.state.checkout.id_indicacao === null && indicacao) {
        try {
          this.$store.dispatch('setIndicacao', indicacao);
        } catch (e) {
          console.log(e);
        }
      }
    }

    await this.$store.dispatch('getOpcionais');

    this.nome = this.$store.state.cotacao.nome;
    this.email = this.$store.state.cotacao.email;
    this.telefone = this.$store.state.cotacao.telefone;

    await RdStation.conversion({
      email: this.email,
      identificador: `Conversão APVS: Checkout`,
    });
  }
}
</script>

<style scoped>

h4 {
  margin: 1.5rem 0;
}

</style>
