import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// import Veiculo from '@/pages/Veiculos';
// import Cliente from '@/pages/Cliente';
import Cotacao from '@/pages/Cotacao';
import Checkout from '@/pages/Checkout';
import Fechamento from '@/pages/Fechamento';
import Sucesso from "@/pages/Sucesso";
import Instrucoes from "@/pages/Instrucoes";

const routes = [
    // {
    //     path: '/',
    //     component: Veiculo
    // },
    // {
    //     path: '/cliente',
    //     name: 'cliente',
    //     component: Cliente
    // },
    {
        path: '/cotacao',
        name: 'cotacao',
        component: Cotacao
    },
    {
        path: '/instrucoes',
        name: 'instrucoes',
        component: Instrucoes
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: Checkout
    },
    {
        path: '/fechamento',
        name: 'fechamento',
        component: Fechamento
    },
    {
        path: '/boleto',
        name: 'boleto',
        component: Sucesso
    },
    {
        path: '/cartao',
        name: 'cartao',
        component: Sucesso
    }
];

const router = new VueRouter({
    routes,
    base: '/',
    mode: 'history'
});

export default router;
