<template>
  <main>
    <div class="box-area">
      <div class="box-single d-flex align-items-center bg-box">
        <form class="container box-container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <video class="video-instrucoes"
                controls
                poster="https://parceirosapvstruck.partnerson.com.br/material/vsl-patrimonio-thumb.png">
                <source
                  src="https://parceirosapvstruck.partnerson.com.br/material/vsl-patrimonio.mp4"
                  type="video/mp4">
              </video>
            </div>
            <div class="col-md-6">
              <contrato/>
            </div>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
/* eslint-disable no-undef */
import Contrato from "@/components/Contrato";
import RdStation from '@/services/rdstation.js';

export default {
  name: 'Instrucoes',
  components: {
    Contrato,
  },
  computed: {
    cotacao() {
      return this.$store.state.cotacao;
    },
  },
  async mounted() {
    // eslint-disable-next-line no-undef
    window.fbq('track', 'PageView');
    window.fbq('track', 'InitiateCheckout');

    const btnVoltar = document.querySelector('.btn-voltar');
    btnVoltar.style.display = 'none';

    let searchParams = new URLSearchParams(window.location.search);
    const cotacaoId = searchParams.get('id');
    if (this.$store.state.cotacao.id === null && cotacaoId) {
      await this.$store.dispatch('setCotacao', cotacaoId);

      const indicacao = searchParams.get('indicacao');
      if (this.$store.state.checkout.id_indicacao === null && indicacao) {
        try {
          await this.$store.dispatch('setIndicacao', indicacao);
        } catch (e) {
          console.log(e);
        }
      }
    }

    await this.$store.dispatch('setIndicacaoInstrucoes');

    await RdStation.conversion({
      email: this.cotacao.email,
      identificador: `Conversão APVS: Vídeo`,
    });
  }
}
</script>

<style scoped>

h4 {
  margin: 1.5rem 0;
}

</style>
