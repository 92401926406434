import api from './api';

export default {

  get(id) {
    return api.get(`/cotacoes/${id}`);
  },

  store(data) {
    return api.post(`/cotacoes`, data);
  },

  update(id, data) {
    return api.put(`/cotacoes/${id}`, data);
  },

  checkout(id, data) {
    return api.post(`/cotacoes/${id}/checkout`, data);
  },

}
