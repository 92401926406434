import Vue from "vue";

export default {
  SET_LOADING_FULL: (state, payload) => state.loading.full = payload,
  SET_LOADING_PAGE: (state, payload) => state.loading.page = payload,
  SET_LOADING_SAVE: (state, payload) => state.loading.save = payload,
  SET_LOADING_MARCAS: (state, payload) => state.loading.marcas = payload,
  SET_LOADING_MODELOS: (state, payload) => state.loading.modelos = payload,
  SET_LOADING_ANOS: (state, payload) => state.loading.anos = payload,
  SET_LOADING_FIPE: (state, payload) => state.loading.fipe = payload,
  SET_LOADING_ESTADOS: (state, payload) => state.loading.estados = payload,
  SET_LOADING_IMPLEMENTOS: (state, payload) => state.loading.implementos = payload,
  SET_LOADING_CEP: (state, payload) => state.loading.cep = payload,
  SET_LOADING_DISCREPANCIA: (state, payload) => state.loading.discrepancia = payload,

  SET_COTACAO: (state, payload) => state.cotacao = payload,
  SET_COTACAO_ID: (state, payload) => state.cotacao.id = payload,
  SET_COTACAO_CLIENTE_ID: (state, payload) => state.cotacao.cliente_id = payload,

  SET_COTACAO_TIPO(state, payload) {
    state.cotacao.tipo = payload.label;
    if(payload.flag) {
      localStorage.clear();
      state.cotacao.pesado = false;
      state.cotacao.marca_id = null;
      state.cotacao.modelo_id = null;
      state.cotacao.ano_id = null;
      state.cotacao.fipe = null;
      state.cotacao.valor_veiculo = null;
      state.cotacao.implemento_id = null;
      state.cotacao.valor_implemento = null;
      state.simulacao.implemento = false;
    }
  },
  SET_COTACAO_PESADO: (state, payload) => state.cotacao.pesado = payload,

  SET_COTACAO_MARCA(state, payload) {
    state.cotacao.marca_id = payload;
    state.erros.marca_id = [];
    state.cotacao.modelo_id = null;
    state.cotacao.ano_id = null;
    state.cotacao.fipe = null;
    state.cotacao.valor_veiculo = null;
  },
  SET_COTACAO_MODELO(state, payload) {
    state.cotacao.modelo_id = payload;
    state.erros.modelo_id = [];
    state.cotacao.ano_id = null;
    state.cotacao.fipe = null;
    state.cotacao.valor_veiculo = null;
  },
  SET_COTACAO_ANO(state, payload) {
    state.cotacao.ano_id = payload;
    state.erros.ano_id = [];
    state.cotacao.fipe = null;
    state.cotacao.valor_veiculo = null;
  },
  SET_COTACAO_FIPE(state, payload) {
    state.cotacao.fipe = payload;
  },
  SET_COTACAO_VALOR_FIPE(state, payload) {
    state.cotacao.valor_fipe = payload;
  },
  SET_IMPLEMENTO_FIXO(state, payload) {
    state.simulacao.implemento = payload;
    if (!payload) {
      state.cotacao.implemento_id = null;
      state.cotacao.valor_implemento = null;
    }
  },
  SET_COTACAO_IMPLEMENTO(state, payload) {
    state.cotacao.implemento_id = payload;
  },
  SET_COTACAO_VALOR_IMPLEMENTO(state, payload) {
    state.cotacao.valor_implemento = payload;
  },
  SET_COTACAO_ESTADO(state, payload) {
    state.cotacao.estado_id = payload;
    state.erros.estado_id = [];
  },
  SET_COTACAO_NOME(state, payload) {
    state.cotacao.nome = payload;
  },
  SET_COTACAO_EMAIL(state, payload) {
    state.cotacao.email = payload;
  },
  SET_COTACAO_TELEFONE(state, payload) {
    state.cotacao.telefone = payload;
  },

  SET_INDICADOR: (state, payload) => state.simulacao.indicador = payload,
  SET_CHECKOUT_INDICACAO: (state, payload) => state.checkout.id_indicacao = payload,
  SET_CHECKOUT: (state, payload) => state.simulacao.checkout = payload,
  SET_CHECKOUT_NOME(state, payload) {
    state.checkout.nome = payload;
  },
  SET_CHECKOUT_EMAIL(state, payload) {
    state.checkout.email = payload;
  },
  SET_CHECKOUT_TELEFONE(state, payload) {
    state.checkout.telefone = payload;
  },
  SET_CHECKOUT_DOCUMENTO(state, payload) {
    state.checkout.documento = payload;
  },
  SET_CHECKOUT_CEP(state, payload) {
    state.checkout.cep = payload;
  },
  SET_CHECKOUT_ENDERECO(state, payload) {
    state.checkout.endereco = payload;
  },
  SET_CHECKOUT_NUMERO(state, payload) {
    state.checkout.numero = payload;
  },
  SET_CHECKOUT_COMPLEMENTO(state, payload) {
    state.checkout.complemento = payload;
  },
  SET_CHECKOUT_BAIRRO(state, payload) {
    state.checkout.bairro = payload;
  },
  SET_CHECKOUT_CIDADE(state, payload) {
    state.checkout.cidade = payload;
  },
  SET_CHECKOUT_ESTADO(state, payload) {
    state.checkout.estado = payload;
  },
  SET_CHECKOUT_METODO(state, payload) {
    state.checkout.metodo = payload;
  },
  SET_CHECKOUT_TOKEN(state, payload) {
    state.checkout.token = payload;
  },
  SET_CHECKOUT_PARCELAS(state, payload) {
    state.checkout.parcelas = payload;
  },
  SET_FATURA(state, payload) {
    state.fatura = payload;
  },

  SET_CARTAO_NOME(state, payload) {
    state.cartao.nome = payload;
  },
  SET_CARTAO_NUMERO(state, payload) {
    state.cartao.numero = payload;
  },
  SET_CARTAO_MES(state, payload) {
    state.cartao.mes = payload;
  },
  SET_CARTAO_ANO(state, payload) {
    state.cartao.ano = payload;
  },
  SET_CARTAO_CODIGO(state, payload) {
    state.cartao.codigo = payload;
  },
  SET_CHECKOUT_PLACA(state, payload) {
    state.checkout.placa = payload;
  },
  SET_CHECKOUT_FIPE(state, payload) {
    state.checkout.fipe = payload;
  },
  SET_PLACA(state, payload) {
    state.placa = payload;
  },
  SET_PLACA_DISCREPANCIA(state, payload) {
    state.simulacao.placa.discrepancia = payload;
  },

  SET_ERROS(state, payload) {
    Object.keys(state.erros.cotacao).forEach(key => {
      let value = [];
      if (key in payload) {
        value = payload[key];
      }
      Vue.set(state.erros.cotacao, key, value);
    });
  },
  SET_ERRO(state, {key, value}) {
    Vue.set(state.erros.cotacao, key, value);
  },
  SET_ERROS_CHECKOUT(state, payload) {
    Object.keys(state.erros.checkout).forEach(key => {
      let value = [];
      if (key in payload) {
        value = payload[key];
      }
      Vue.set(state.erros.checkout, key, value);
    });
  },
  SET_ERRO_CHECKOUT(state, {key, value}) {
    Vue.set(state.erros.checkout, key, value);
  },
  SET_ERROS_CARTAO(state, payload) {
    Object.keys(state.erros.cartao).forEach(key => {
      let value = [];
      if (key in payload) {
        value = payload[key];
      }
      Vue.set(state.erros.cartao, key, value);
    });
  },
  SET_ERRO_CARTAO(state, {key, value}) {
    Vue.set(state.erros.cartao, key, value);
  },

  SET_MARCAS(state, payload) {
    state.marcas = payload;
  },
  SET_MODELOS(state, payload) {
    state.modelos = payload;
  },
  SET_ANOS(state, payload) {
    state.anos = payload;
  },
  SET_ESTADOS(state, payload) {
    state.estados = payload;
  },
  SET_FIPE(state, payload) {
    state.fipe = payload;
  },
  SET_IMPLEMENTOS(state, payload) {
    state.implementos = payload;
  },
  SET_ADICIONAIS(state, payload) {
    state.adicionais = payload;
  },
  SET_BLACKLIST(state, payload) {
    state.cotacao.blacklist = payload;
  },

  SET_OPCIONAIS_ASSISTENCIA(state, payload) {
    state.opcionais.assistencia = payload;
  },
  SET_OPCIONAL_ASSISTENCIA(state, payload) {
    state.simulacao.opcionais.assistencia = payload;
  },
  SET_OPCIONAIS_PAR(state, payload) {
    state.opcionais.par = payload;
  },
  SET_OPCIONAL_PAR(state, payload) {
    state.simulacao.opcionais.par = payload;
  },
  SET_OPCIONAIS_CARRO(state, payload) {
    state.opcionais.carro = payload;
  },
  SET_OPCIONAL_CARRO(state, payload) {
    state.simulacao.opcionais.carro = payload;
  },
  SET_OPCIONAIS_VIDRO(state, payload) {
    state.opcionais.vidro = payload;
  },
  SET_OPCIONAL_VIDRO(state, payload) {
    state.simulacao.opcionais.vidro = payload;
  },
  SET_OPCIONAIS_MORTE(state, payload) {
    state.opcionais.morte = payload;
  },
  SET_OPCIONAL_MORTE(state, payload) {
    state.simulacao.opcionais.morte = payload;
  },
};
