<template>
  <form id="contrato">

    <div class="form-group">
      <div class="v-input input-field">
        <fieldset class="fieldset" :class="{'error-field': isValid(erros.placa)}">
          <legend>Placa</legend>
          <b-form-input
            id="placa"
            v-model="placa"
            placeholder="Placa"
            maxlength="8"
            v-facade="'SSS-#X##'"
            v-on:keyup="validInput('placa')"
          />
        </fieldset>
      </div>
      <b-form-invalid-feedback :force-show="isValid(erros.placa)">
        <div v-for="(erro, index) in erros.placa" :key="`error-checkout-placa-${index}`">
          {{ erro }}
        </div>
      </b-form-invalid-feedback>
    </div>

    <div class="form-group" v-if="fipes.length > 0">
      <div class="v-input">
        <fieldset class="fieldset" :class="{'error-field': isValid(erros.fipe)}">
          <legend><span>Modelo</span></legend>
          <div class="v-select">
            <v-select
              v-model="modelo"
              label="marca_modelo"
              :clearable="false"
              placeholder="Selecione um Modelo"
              :reduce="opt => opt.codigo"
              :options="fipes">
              <div slot="no-options">Modelo não encontrado</div>
            </v-select>
          </div>
        </fieldset>
      </div>
      <b-form-invalid-feedback :force-show="isValid(erros.fipe)">
        <div v-for="(erro, index) in erros.fipe" :key="`error-checkout-fipe-${index}`">
          {{ erro }}
        </div>
      </b-form-invalid-feedback>
    </div>

    <b-modal
      id="modal-discrepancia"
      v-model="modal"
      no-close-on-esc
      no-close-on-backdrop
      centered
      hide-header
      ok-only
      @ok="onCloseModal">
      Foi detectado uma discrepância entre a placa informada e os dados do veículo informado anterior.
      <p class="mt-2">Iremos atualizar sua proposta.</p>

      <template #modal-footer="{ ok }">
        <b-overlay :show="modalLoading" rounded>
          <b-button size="sm" variant="success" @click="ok()">
            Continuar
          </b-button>
        </b-overlay>
      </template>
    </b-modal>

    <b-modal
      id="modal-blacklist"
      v-model="blacklist"
      ok-variant="main"
      ok-only
      title="Mensagem">
      <div class="row">
        <div class="col-md-12 text-center">
          <b-icon icon="exclamation-circle" class="icon-message"></b-icon>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 col-md-offset-2"></div>
        <div class="col-md-8 text-center">
          <p>No momento seu veículo <b>não possui aceitação</b> em nosso sistema.</p>
        </div>
        <div class="col-md-2 col-md-offset-2"></div>
      </div>
    </b-modal>

  </form>
</template>

<script>
export default {
  name: "Placa",
  computed: {
    erros() {
      return this.$store.state.erros.checkout;
    },
    placa: {
      get() {
        return this.$store.state.checkout.placa;
      },
      set(value) {
        this.$store.dispatch('setPlaca', value);
      }
    },
    modelo: {
      get() {
        return this.$store.state.checkout.fipe;
      },
      set(value) {
        this.$store.dispatch('setPlacaModelo', value);
      }
    },
    modal: {
      get() {
        return this.$store.state.simulacao.placa.discrepancia;
      },
      set(value) {
        this.$store.commit('SET_PLACA_DISCREPANCIA', value);
      }
    },
    dados() {
      return this.$store.state.placa;
    },
    fipes() {
      return this.dados.fipes;
    },
    modalLoading() {
      return this.$store.state.loading.discrepancia;
    },
    blacklist() {
      return this.$store.state.cotacao.blacklist;
    },
  },
  mounted() {
  },
  methods: {
    validInput() {
      if (!this.isNullOrEmpty(this.placa)) {
        this.$store.commit('SET_ERRO_CHECKOUT', {key: 'placa', value: []});
      }
    },
    isNullOrEmpty(value) {
      return value === null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0);
    },
    isValid(input) {
      return input.length > 0 ? true : null;
    },
    async onCloseModal(event) {
      event.preventDefault();

      await this.$store.dispatch('setUpdateCotacao');

      if (!this.blacklist) {
        const query = {
          id: this.$store.state.cotacao.id,
          indicacao: this.$store.state.checkout.id_indicacao,
          ref: this.$store.state.simulacao.indicador,
        };
        this.$router.push({name: 'cotacao', query});
      }
    },
  },
}
</script>

<style scoped>

h4 {
  margin: 1.5rem 0;
}

</style>
