import axios from 'axios';
import getCookie from 'js-cookie';

const instance = axios.create({
  baseURL: 'https://www.rdstation.com.br/api/1.3',
});

let firstConversion = true;

export default {
  async conversion(data) {
    // Remove todos os ( "", 0, false, null, undefined )
    data = Object.entries(data).reduce((a, [k, v]) => {
      return (v ? (a[k] = v, a) : a);
    }, {});

    const response = await instance
      .post('/conversions', { ...this.getData(), ...data });
    return response.data;
  },

  getData() {
    let data = {
      token_rdstation: 'e3e4df45640b45e8ca60d87fe7c509a5',
    };
    if (firstConversion) {
      firstConversion = false;
      data = {
        ...data,
        traffic_source: getCookie.get('__trf.src'),
        c_utmz: getCookie.get('__utmz'),
        client_id: getCookie.get('rdtrk'),
      };
    }
    return data;
  }
}
