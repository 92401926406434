export default {

  loading:{
    full: false,
    page: false,
    save: false,
    marcas: false,
    modelos: false,
    anos: false,
    fipe: false,
    estados: false,
    implementos: false,

    cep: false,
    discrepancia: false,
  },

  cotacao: {
    id: null,
    tipo: null,
    pesado: false,
    marca_id: null,
    modelo_id: null,
    ano_id: null,
    marca: null,
    modelo: null,
    ano: null,
    fipe: null,
    valor_fipe: null,
    valor_adesao: null,
    valor_plano: null,
    implemento_id: null,
    valor_implemento: null,
    nome: null,
    email: null,
    telefone: null,
    estado_id: null,
    blacklist: false,
    plano_estado_id: null,
  },

  checkout: {
    id_indicacao: null,
    nome: null,
    email: null,
    telefone: null,
    documento: null,
    cep: null,
    endereco: null,
    numero: null,
    complemento: null,
    bairro: null,
    estado: null,
    cidade: null,
    metodo: null,
    parcelas: null,
    token: null,

    placa: null,
    fipe: null,
  },

  cartao: {
    nome: null,
    numero: null,
    mes: null,
    ano: null,
    codigo: null,
  },

  simulacao: {
    placa: {
      discrepancia: false,
    },
    indicador: null,
    implemento: false,
    checkout: false,
    opcionais: {
      assistencia: 0,
      par: 0,
      carro: 0,
      vidro: 0,
      morte: 0,
    },
  },

  fatura: {
    id: null,
    id_indicacao: null,
    id_fatura: null,
    nome: null,
    url: null,
    valor: null,
    tipo: null,
    situacao: null,
    registro: null,
    data_criacao: null,
    data_atualizacao: null,
    data_vencimento: null,
    data_pagamento: null,
  },

  erros: {
    cotacao: {
      marca_id: [],
      modelo_id: [],
      ano_id: [],
      fipe: [],
      valor_fipe: [],

      implemento_id: [],
      valor_implemento: [],

      nome: [],
      email: [],
      telefone: [],
      estado_id: [],
      error_blacklist: [],
    },

    checkout: {
      nome: [],
      email: [],
      telefone: [],
      documento: [],
      cep: [],
      endereco: [],
      numero: [],
      complemento: [],
      bairro: [],
      estado: [],
      cidade: [],
      metodo: [],
      placa: [],
      fipe: [],
    },
    cartao: {
      nome: [],
      numero: [],
      validade: [],
      mes: [],
      ano: [],
      codigo: [],
    },
  },

  marcas: [],
  modelos: [],
  anos: [],
  estados:[],
  cidades:[],
  implementos:[],
  adicionais:[],

  opcionais: {
    assistencia: [],
    par: [],
    carro: [],
    vidro: [],
    morte: [],
  },

  fipe: {

  },

  placa: {
    veiculo: null,
    fipes: [],
  },
};
