<template>
  <main>
    <div class="box-area">
      <div class="box-single d-flex align-items-center bg-box">
        <div class="container box-container">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-8">
              <div class="box-form">

                <div class="slider-text text-center">
                  <b-icon icon="check2-circle" variant="success" font-scale="5" class="mb-4"></b-icon>
                  <h3 class="title">Obrigado pela adesão</h3>

                  <template v-if="metodo === 'boleto'">

                    <h6 class="my-2">Segue o link de boleto:</h6>

                    <b-btn
                      class="mb-4"
                      variant="main"
                      size="lg"
                      :href="link_boleto"
                      target="_blank"
                    >
                      ABRIR BOLETO
                    </b-btn>

                  </template>
                  <template v-else-if="metodo === 'cartao'">
                    <template v-if="status === 'pending'">

                      <h6 class="my-2">Houve uma falha ao tentar cobrar nesse cartão, tente novamente no link abaixo:</h6>

                      <b-btn
                        class="mb-4"
                        variant="main"
                        size="lg"
                        :href="link_boleto"
                        target="_blank"
                      >
                        ABRIR CHECKOUT
                      </b-btn>

                    </template>
                    <template v-else>

                      <h6 class="my-2">Pagamento realizado com sucesso!</h6>

                      <b-btn
                        class="mb-4"
                        variant="main"
                        size="lg"
                        :href="link_boleto"
                        target="_blank"
                      >
                        ABRIR COMPROVANTE
                      </b-btn>

                    </template>
                  </template>

                  <h6 class="mt-4 mb-4">Um consultor entrará em contato para assinatura dos termos de adesão e
                    agendamento da vistoria.</h6>
                  <div class="hr-footer"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import RdStation from '@/services/rdstation.js';

export default {
  name: 'Fechamento',
  data() {
    return {}
  },
  computed: {
    metodo() {
      return this.$store.state.checkout.metodo;
    },
    link_boleto() {
      return this.$store.state.fatura.url;
    },
    status() {
      return this.$store.state.fatura.status;
    }
  },
  async mounted() {
    // eslint-disable-next-line no-undef
    window.fbq('track', 'PageView');
    // eslint-disable-next-line no-undef
    window.fbq('trackCustom', 'Obrigado');

    let metodo;
    if (this.metodo === 'boleto') {
      metodo = 'Boleto';
      window.fbq('track', 'Purchase', {value: 70, currency: 'BRL'});
    } else if (this.metodo === 'cartao') {
      metodo = 'Cartão';
      window.fbq('track', 'Purchase', {value: 350, currency: 'BRL'});
    }

    const btnVoltar = document.querySelector('.btn-voltar');
    btnVoltar.style.display = 'none';

    await RdStation.conversion({
      email: this.cotacao.email,
      identificador: `Conversão APVS: ${metodo}`,
    });
  }
}
</script>

<style scoped>

h4 {
  margin: 0;
}

.box-form {
  background: #FFF;
  border-radius: 4px;
  padding: 30px 20px;
  border: 1px solid #d8e2ef;
  position: relative;
  top: 30px;
}


</style>
