import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import router from './routes/router.js'
import VueDirectiveMask from 'vue-directive-mask'
import InputFacade from 'vue-input-facade'
import money from 'v-money'
import Notifications from 'vue-notification'
import store from './store'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import titleMixin from './mixins/titleMixin';
import InlineSvg from 'vue-inline-svg';

// eslint-disable-next-line no-undef
Iugu.setAccountID(process.env.VUE_APP_IUGU_ACCOUNT_ID);
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-undef
  Iugu.setTestMode(true);
}

Vue.component('v-select', vSelect)
Vue.use(money, {precision: 2, prefix: 'R$ ', masked: false, decimal: ',', thousands: '.'})
Vue.use(VueDirectiveMask)
Vue.use(InputFacade)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Notifications)
Vue.mixin(titleMixin)
Vue.component('inline-svg', InlineSvg)

Vue.config.productionTip = false;
Vue.config.devtools = true;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
