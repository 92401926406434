import api from '@/services/api.js';
import fipe from '@/services/fipe.js';
import cotacao from '@/services/cotacao.js';
import cliente from '@/services/cliente.js';
import apvs from "@/services/apvs";
import placas from "@/services/placas";

export default {
  async setTipo({commit}, tipo) {

    commit('SET_COTACAO_TIPO', tipo);
    commit('SET_LOADING_MARCAS', true);
    commit('SET_MARCAS', []);

    try {
      const response = await fipe.getMarcas(tipo.label);
      commit('SET_MARCAS', response.data.data);
    } catch (error) {
      console.log(error.response);
      throw error;
    } finally {
      commit('SET_LOADING_MARCAS', false);
    }
  },
  async setMarca({state, commit}, marca) {
    commit('SET_COTACAO_MARCA', marca);
    commit('SET_LOADING_MODELOS', true);
    commit('SET_MODELOS', []);
    commit('SET_ANOS', []);

    const tipo = state.cotacao.tipo;
    try {
      if(marca) {
        const response = await fipe.getModelos(tipo, marca);
        commit('SET_MODELOS', response.data.data);
      }
    } catch (error) {
      console.log(error.response);
      throw error;
    } finally {
      commit('SET_LOADING_MODELOS', false);
    }
  },
  async setModelo({state, commit}, modelo) {
    commit('SET_COTACAO_MODELO', modelo);
    commit('SET_LOADING_ANOS', true);
    commit('SET_ANOS', []);

    const tipo = state.cotacao.tipo;
    const marca = state.cotacao.marca_id;

    try {
      if(marca && modelo) {
        const response = await fipe.getAnos(tipo, marca, modelo);
        commit('SET_ANOS', response.data.data);
      }
    } catch (error) {
      console.log(error.response);
      throw error;
    } finally {
      commit('SET_LOADING_ANOS', false);
    }
  },
  async setAno({state, commit}, ano) {
    commit('SET_COTACAO_ANO', ano);
    commit('SET_LOADING_FIPE', true);
    commit('SET_FIPE', {});

    const tipo = state.cotacao.tipo;
    const marca = state.cotacao.marca_id;
    const modelo = state.cotacao.modelo_id;

    try {
      if(marca && modelo && ano) {
        const response = await fipe.getVeiculo(tipo, marca, modelo, ano);
        const data = response.data.data;
        commit('SET_FIPE', data);
        commit('SET_COTACAO_MARCA', data.marca);
        commit('SET_COTACAO_MODELO', data.name);
        commit('SET_COTACAO_ANO', data.ano);
        commit('SET_COTACAO_FIPE', data.fipe);
        commit('SET_COTACAO_VALOR_FIPE', data.valor);
      }
    } catch (error) {
      console.log(error.response);
      throw error;
    } finally {
      commit('SET_LOADING_FIPE', false);
    }
  },
  async saveCotacao({state, commit}) {
    commit('SET_LOADING_PAGE', true);
    try {
      const response = await cotacao.store(state.cotacao);
      commit('SET_COTACAO', response.data.data);

    } catch (error) {
      console.log(error);
      commit('SET_ERROS', error.response.data.errors);
      if(!error.response.data.errors.error_blacklist[0]) {
        commit('SET_BLACKLIST', true);
      }
      throw error;
    } finally {
      commit('SET_LOADING_PAGE', false);
    }
  },
  async saveCliente({state, commit}) {
    commit('SET_LOADING_PAGE', true);

    try {
      const cotacaoId = state.cotacao.id;
      const clienteId = state.cotacao.cliente_id;

      if (clienteId) {
        await cliente.update(cotacaoId, clienteId, state.cotacao);
      } else {
        const response = await cliente.store(cotacaoId, state.cotacao);
        commit('SET_COTACAO_CLIENTE_ID', response.data.data.id);
      }

    } catch (error) {
      console.log(error);
      commit('SET_ERROS', error.response.data.errors);

      throw error;
    } finally {
      commit('SET_LOADING_PAGE', false);
    }
  },
  async setIndicacao({state, commit}, id_indicacao) {

    if (!isNaN(Number(id_indicacao))) {
      commit('SET_CHECKOUT_INDICACAO', id_indicacao);
    } else {
      commit('SET_CHECKOUT_INDICACAO', null);
      return;
    }

    const cotacaoId = state.cotacao.id;

    try {
      api.post(`/cotacoes/${cotacaoId}/indicacoes/${id_indicacao}`)
      const response = await apvs.getCheckout(id_indicacao);
      commit('SET_CHECKOUT', response?.data?.checkout ?? false);
    } catch (error) {
      commit('SET_CHECKOUT', false);
      console.log(error.response);
      throw error;
    }
  },
  async setIndicacaoInstrucoes({state}) {

    const cotacaoId = state.cotacao.id;
    const indicacaoId = state.checkout.id_indicacao;

    await api.post(`/cotacoes/${cotacaoId}/indicacoes/${indicacaoId}/instrucoes`);
  },
  async createCheckout({state, commit}) {
    commit('SET_LOADING_PAGE', true);
    try {
      const cotacaoId = state.cotacao.id;
      const response = await cotacao.checkout(cotacaoId, state.checkout);

      console.log(response);
      commit('SET_FATURA', response.data.data);

    } catch (error) {
      console.log(error);
      commit('SET_ERROS_CHECKOUT', error.response.data.errors);
      throw error;
    } finally {
      commit('SET_LOADING_PAGE', false);
    }
  },
  async loadEstados({commit}) {
    commit('SET_LOADING_ESTADOS', true);
    commit('SET_ESTADOS', []);

    try {
      const response = await api.get('/estados');
      commit('SET_ESTADOS', response.data);
    } catch (error) {
      commit('SET_ESTADOS', []);
      console.log(error.response);
      throw error;
    } finally {
      commit('SET_LOADING_ESTADOS', false);
    }
  },
  async loadImplementos({commit}) {
    commit('SET_LOADING_IMPLEMENTOS', true);

    try {
      const response = await api.get('/implementos');
      commit('SET_IMPLEMENTOS', response.data);
    } catch (error) {
      console.log(error.response);
      throw error;
    } finally {
      commit('SET_LOADING_IMPLEMENTOS', false);
    }
  },
  async setCheckoutCep({commit}, value) {
    commit('SET_CHECKOUT_CEP', value);

    const cep = value.replace(/\D/g,'');

    if (cep.length !== 8) {
      return;
    }

    commit('SET_LOADING_CEP', true);

    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();

      commit('SET_CHECKOUT_ENDERECO', data.logradouro);
      commit('SET_CHECKOUT_BAIRRO', data.bairro);
      commit('SET_CHECKOUT_ESTADO', data.uf);
      commit('SET_CHECKOUT_CIDADE', data.localidade);
      console.log(data);

    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(async () => {
        commit('SET_LOADING_CEP', false);
      }, 500);
    }
  },
  async setCotacao({commit}, id) {

    commit('SET_COTACAO_ID', id);

    try {
      const response = await api.get(`/getPlano/${id}`);

      if (response.data.data) {
        commit('SET_COTACAO', response.data.data);
      }
    } catch (error) {
      console.log(error.response);
    }
  },
  async getOpcionais({state, commit}) {

    const tipo = state.cotacao.tipo;
    const valorFipe = Number(state.cotacao.valor_fipe ?? 0);
    const planoEstadoId = state.cotacao.plano_estado_id;

    try {
      const response = await api.get(`/planoEstado/${planoEstadoId}`);

      const data = response.data.data;
      const opcionais = data.opcionais;

      const opcionaisPar = [];
      const opcionaisAssistencias = [];
      const opcionaisVidros = [];
      const opcionaisCarro = [];
      const opcionaisMorte = [];

      opcionais.forEach(opcional => {

        if (opcional.opcional_tipo_id === 1) {

          if (tipo === 1 && valorFipe >= 121000.01) {
            commit('SET_OPCIONAL_PAR', 0);

            if (opcional.valor === 0) {
              opcional.disabled = true;
            } else if (opcional.valor > 0) {
              opcional.valor = 0.00;
            }
          }

          opcionaisPar.push(opcional);

        } else if (opcional.opcional_tipo_id === 2) {
          opcionaisAssistencias.push(opcional);
        } else if (opcional.opcional_tipo_id === 3) {
          opcionaisVidros.push(opcional);
        } else if (opcional.opcional_tipo_id === 4) {
          opcionaisCarro.push(opcional);
        } else if (opcional.opcional_tipo_id === 5) {
          opcionaisMorte.push(opcional);
        }
      });

      if (opcionaisVidros.length > 0) {
        opcionaisVidros.unshift({descricao: 'Nenhum', valor: 0});
      }
      if (opcionaisCarro.length > 0) {
        opcionaisCarro.unshift({descricao: 'Nenhum', valor: 0});
      }
      if (opcionaisMorte.length > 0) {
        opcionaisMorte.unshift({descricao: 'Nenhum', valor: 0});
      }

      commit('SET_OPCIONAIS_PAR', opcionaisPar);
      commit('SET_OPCIONAIS_ASSISTENCIA', opcionaisAssistencias);
      commit('SET_OPCIONAIS_VIDRO', opcionaisVidros);
      commit('SET_OPCIONAIS_CARRO', opcionaisCarro);
      commit('SET_OPCIONAIS_MORTE', opcionaisMorte);

    } catch (e) {
      console.log(e);
    }

  },
  async setPlaca({commit}, placa) {

    commit('SET_CHECKOUT_PLACA', placa);

    if (placa.length === 8) {
      try {
        const response = await placas.get(placa);

        if (response.data.data) {
          let data = response.data.data;
          commit('SET_PLACA', data);
          commit('SET_CHECKOUT_FIPE', null);
        }
      } catch (error) {
        console.log(error.response);
      }
    }

  },
  async setPlacaModelo({state, commit}, fipe) {

    commit('SET_CHECKOUT_FIPE', fipe);

    if (fipe === state.cotacao.fipe) {
      return;
    }

    commit('SET_PLACA_DISCREPANCIA', true);

  },
  async setUpdateCotacao({state, commit, dispatch}) {

    commit('SET_BLACKLIST', false);
    commit('SET_LOADING_DISCREPANCIA', true);

    const id = state.cotacao.id;

    const data = {
      fipe: state.checkout.fipe,
      ano: state.placa.veiculo.ano,
    };

    try {
      const response = await cotacao.update(id, data);
      commit('SET_COTACAO', response.data.data);
      dispatch('setAno', response.data.data.ano_id)
    } catch (error) {
      console.log(error.response);
      if(error.response.data.errors.error_blacklist) {
        commit('SET_BLACKLIST', true);
      }
      throw error;
    } finally {
      commit('SET_LOADING_DISCREPANCIA', false);
      commit('SET_PLACA_DISCREPANCIA', false);
    }

  },
};
