export default {
  getValorMensal(state, getters) {
    let mensal = Number(state.cotacao?.valor_plano ?? 0);
    mensal += getters.getOpcionalAssistencia?.valor ?? 0;
    mensal += getters.getOpcionalPar?.valor ?? 0;
    mensal += getters.getOpcionalCarro?.valor ?? 0;
    mensal += getters.getOpcionalVidro?.valor ?? 0;
    mensal += getters.getOpcionalMorte?.valor ?? 0;

    return mensal;
  },

  getOpcionalAssistencia(state) {
    const selecionado = state.simulacao.opcionais.assistencia;
    const opcoes = state.opcionais.assistencia;

    return opcoes.find(opcao => opcao.valor === selecionado);
  },
  getOpcionalPar(state) {
    const selecionado = state.simulacao.opcionais.par;
    const opcoes = state.opcionais.par;

    return opcoes.find(opcao => opcao.valor === selecionado);
  },
  getOpcionalCarro(state) {
    const selecionado = state.simulacao.opcionais.carro;
    const opcoes = state.opcionais.carro;

    if (selecionado === 0) {
      return undefined;
    }

    return opcoes.find(opcao => opcao.valor === selecionado);
  },
  getOpcionalVidro(state) {
    const selecionado = state.simulacao.opcionais.vidro;
    const opcoes = state.opcionais.vidro;

    if (selecionado === 0) {
      return undefined;
    }

    return opcoes.find(opcao => opcao.valor === selecionado);
  },
  getOpcionalMorte(state) {
    const selecionado = state.simulacao.opcionais.morte;
    const opcoes = state.opcionais.morte;

    if (selecionado === 0) {
      return undefined;
    }

    return opcoes.find(opcao => opcao.valor === selecionado);
  }

};
