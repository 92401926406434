import api from './api';

export default {

  store(cotacao, data) {
    return api.post(`/cotacoes/${cotacao}/clientes`, data);
  },

  update(cotacao, cliente, data) {
    return api.get(`/cotacoes/${cotacao}/clientes/${cliente}`, data);
  },

}
