import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_APVS_URL
});

export default {

  getCheckout(id_indicacao) {
    return api.get(`/checkout/indicacao/${id_indicacao}`);
  },

}
