<template>
  <div id="app">
    <Header/>
    <router-view />
    <Footer/>
    <notifications position="bottom right" group="main" />
    <div>
      <b-overlay :show="loadingFull" no-wrap bg-color="rgba(0,0,0,.4)">
      </b-overlay>
    </div>
  </div>
</template>

<script>

import './css/global.css';

import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  title: 'APVS Proteção Veicular', 
  components: {
    Footer,
    Header
  },
  computed: {
    loadingFull() {
      return this.$store.state.loading.full;
    }
  },
  watch: { 
     '$route.name': {
        handler: function() {
          const seletor = document.querySelector('html');
          seletor.classList.add('overlay-body');
          this.$store.commit('SET_LOADING_FULL', true);
          setTimeout(() => {
            seletor.classList.remove('overlay-body');
            this.$store.commit('SET_LOADING_FULL', false);
          }, 1000);
        },
        deep: true,
        immediate: true
      }
  }
}
</script>

<style>

  .overlay-body {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .overlay-body footer {
     position: inherit;
  }

</style>