<template>
  <form id="cartao">

    <div class="w-100 border-top py-2">
      <strong>Dados do Cartão</strong>
    </div>

    <div class="form-group">
      <div class="v-input input-field">
        <fieldset class="fieldset" :class="{'error-field': isValid(erros.nome)}">
          <legend>Nome</legend>
          <b-form-input
            id="nome"
            v-model="nome"
            placeholder="Nome como escrito no cartão"
            data-iugu="full_name"
            v-on:keyup="validInput('nome')"
          />
        </fieldset>
      </div>
      <b-form-invalid-feedback :force-show="isValid(erros.nome)">
        <div v-for="(erro, index) in erros.nome" :key="`error-cartao-nome-${index}`">
          {{ erro }}
        </div>
      </b-form-invalid-feedback>
    </div>

    <div class="form-group">
      <div class="v-input input-field">
        <fieldset class="fieldset" :class="{'error-field': isValid(erros.numero)}">
          <legend>Número</legend>
          <b-form-input
            id="numero"
            v-model="numero"
            placeholder="Número do cartão"
            data-iugu="number"
            v-on:keyup="validInput('numero')"
          />
        </fieldset>
      </div>
      <b-form-invalid-feedback :force-show="isValid(erros.numero)">
        <div v-for="(erro, index) in erros.numero" :key="`error-cartao-numero-${index}`">
          {{ erro }}
        </div>
      </b-form-invalid-feedback>
    </div>

    <div class="row">

      <div class="form-group col-6 col-sm-4">
        <div class="v-input input-field">
          <fieldset class="fieldset d-flex" :class="{'error-field': isValid(erros.validade)}">
            <legend>Data de Validade</legend>
            <b-form-input
              class="text-center col-4"
              id="mes"
              v-model="mes"
              placeholder="Mês"
              maxlength="2"
              v-on:keyup="validInput('mes')"
            />
            <span style="line-height: 2rem">/</span>
            <b-form-input
              class="text-center"
              id="ano"
              v-model="ano"
              placeholder="Ano"
              maxlength="4"
              v-on:keyup="validInput('ano')"
            />
            <input type="hidden" v-model="validade" data-iugu="expiration" />
          </fieldset>
        </div>
        <b-form-invalid-feedback :force-show="isValid(erros.validade)">
          <div v-for="(erro, index) in erros.validade" :key="`error-cartao-validade-${index}`">
            {{ erro }}
          </div>
        </b-form-invalid-feedback>
      </div>

      <div class="form-group col-6 col-sm-8">
        <div class="v-input input-field">
          <fieldset class="fieldset" :class="{'error-field': isValid(erros.codigo)}">
            <legend>CVV</legend>
            <b-form-input
              id="codigo"
              v-model="codigo"
              placeholder="Código de Segurança"
              data-iugu="verification_value"
              v-on:keyup="validInput('codigo')"
            />
          </fieldset>
        </div>
        <b-form-invalid-feedback :force-show="isValid(erros.codigo)">
          <div v-for="(erro, index) in erros.codigo" :key="`error-cartao-codigo-${index}`">
            {{ erro }}
          </div>
        </b-form-invalid-feedback>
      </div>

    </div>

  </form>
</template>

<script>
export default {
  name: "Cartao",
  computed: {
    cartao() {
      return this.$store.state.cartao;
    },
    erros() {
      return this.$store.state.erros.cartao;
    },
    nome: {
      get() {
        return this.$store.state.cartao.nome;
      },
      set(value) {
        this.$store.commit('SET_CARTAO_NOME', value);
      }
    },
    numero: {
      get() {
        return this.$store.state.cartao.numero;
      },
      set(value) {
        this.$store.commit('SET_CARTAO_NUMERO', value);
      }
    },
    mes: {
      get() {
        return this.$store.state.cartao.mes;
      },
      set(value) {
        this.$store.commit('SET_CARTAO_MES', value);
      }
    },
    ano: {
      get() {
        return this.$store.state.cartao.ano;
      },
      set(value) {
        this.$store.commit('SET_CARTAO_ANO', value);
      }
    },
    codigo: {
      get() {
        return this.$store.state.cartao.codigo;
      },
      set(value) {
        this.$store.commit('SET_CARTAO_CODIGO', value);
      }
    },
    validade: function () {
      return this.mes + '/' + this.ano;
    },
  },
  methods: {
    validInput(name) {
      if (Object.hasOwn(this.cartao, name)) {
        if (!this.isNullOrEmpty(this.cartao[name])) {
          this.$store.commit('SET_ERRO_CARTAO', {key: name, value: []});
        }
      }
    },
    isNullOrEmpty(value) {
      return value === null || value === '';
    },
    isValid(input) {
      return input.length > 0 ? true : null;
    }
  },
}
</script>
